import React from 'react';

import { Button } from '../../../redesign/components/shared';

import { ContentBlockProps } from './contentBlock.types';

import { Wrapper, Container } from '../../../redesign/theme/GlobalStyles';
import { Flex, Visual, Text, Label, Title, Body } from './ContentBlock.styles';

const ContentBlock: React.FC<ContentBlockProps> = ({ params }) => {
  const img = params?.image?.[0];
  const pageURL = params?.internal_link?.[0];
  const hasLink = pageURL || params?.cta_url !== null;
  const btnLink = pageURL ? pageURL._slug : params?.cta_url;

  return (
    <Wrapper>
      <Container maxWidth={'1200px'}>
        <Flex isLeftDirection={params?.image_position!}>
          <Visual>
            <img
              loading='lazy'
              src={img?.cdn_files?.[0]?.url || img?.url}
              width={img?.width}
              height={img?.height}
              alt={img?.name}
            />
          </Visual>
          <Text>
            {params?.label && <Label>{params?.label}</Label>}
            <Title>{params?.headline}</Title>
            <Body dangerouslySetInnerHTML={{ __html: `${params?.text}` }}/>
            {params?.cta_label && hasLink && (
              <Button
                nativeLink={!!params?.cta_url}
                buttontype={params?.button_type}
                text={params?.cta_label}
                url={btnLink}
              />
            )}
          </Text>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default ContentBlock;
