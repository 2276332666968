import React from 'react';
import { useSelector } from 'react-redux';
import { checkLoggedIn } from '../../../../../../components/utils/_app-helpers';
import HeaderLoggedOut from './LoggedOut';
import HeaderLoggedIn from './LoggedIn';
import HeaderCompleted from './Completed';

const EYCHeader = () => {
  const isLoggedIn = checkLoggedIn();
  const currentUser = useSelector((state: any) => state.currentUser);
  const isFinished = false;
  // const isFinished = useSelector((state: any) => state?.infoEYC?.campaignGames[0]?.isFinished);

  return (
    isFinished ?
      <HeaderCompleted />
      : (isLoggedIn && currentUser && !!currentUser.accountID) ? (
          <HeaderLoggedIn />
        ) :
        <HeaderLoggedOut />
  );
};

export default EYCHeader;