import styled, { css } from 'styled-components';

type FlexProps = {
  isLeftDirection: boolean;
};

export const Flex = styled.div<FlexProps>(({ theme, isLeftDirection }) => {
  const { mediaQueries, size } = theme;

  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    align-content: center;

    position: relative;
    width: 100%;

    ${mediaQueries.minWidthSM} {
      flex-direction: ${() => (isLeftDirection ? 'row-reverse' : 'row')};
      
      > :last-child {
        padding-left: ${() => (!isLeftDirection ? `${size.px55}` : 0)};
        padding-right: ${() => (isLeftDirection ? `${size.px55}` : 0)};
      }
    }
  `;
});

export const Text = styled.section(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    ${mediaQueries.minWidthSM} {
      width: 50%;
      max-width: 485px;
    }
  `;
});

export const Title = styled.h2(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    width: 100%;
    font-size: ${size.px24};

    ${mediaQueries.minWidthSM} {
      font-size: ${size.px32};
    }
  `;
});

export const Body = styled.div(({ theme }) => {
  const { font, color, size, mediaQueries } = theme;

  return css`
    width: 100%;
    color: ${color.paragraph};
    margin: ${size.px8} auto ${size.px30};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    
    p, span {
      margin-top: ${size.px8};
    }

    h1, h2, h3, h4, h5, h6, p, a, span{
      color: ${color.paragraph};
    }

    a {
      font-weight: ${font.weight.semiBold};
      text-decoration: underline;
    }

    ${mediaQueries.minWidthSM} {
      margin-top: ${size.px16};
    }
  `;
});

export const Label = styled.span(({ theme }) => {
  const { color, size } = theme;

  return css`
    display: inline-block;
    color: ${color.paragraph};
    margin: ${size.px14} auto ${size.px8};
  `;
});

export const Visual = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;

    ${mediaQueries.minWidthSM} {
      width: 50%;
    }

    img {
      width: 100%;
      height: auto;
    }
  `;
});
