import React from 'react';
import { shopLang } from '../../../../../../../config/config';
import i18n from '../../../../../../../config/i18n';
import { Button } from '../../../../../../../redesign/components/shared';
import {
  ButtonWrapper,
  Container,
  Content,
  HeaderImage,
  KeysText,
  KeysWrapper,
  Text,
  Title,
  Wrapper
} from '../EYCHeader.styles';
import {
  HeaderBoxesNl1,
  KeyBlue,
  KeyGold,
  HeaderBoxesFR1,
  HeaderBoxesFR2,
  HeaderBoxesNL2
} from '../../../assets';

const HeaderLoggedIn = () => {
  const phase: number = 2;
  const keys: number = 5;
  const headerImg = phase === 1 ? (
    shopLang === 'be_fr' ? HeaderBoxesFR1 : HeaderBoxesNl1
  ) : (
    shopLang === 'be_fr' ? HeaderBoxesFR2 : HeaderBoxesNL2
  );

  return (
    <Wrapper phase={phase}>
      <Container>
        <HeaderImage>
          <img src={headerImg} alt='boxes' />
        </HeaderImage>

        <Content>
          <div>
            {
              phase === 1 ?
                <>
                  <Title>{i18n.t('eyc_2024_1_header_title')}</Title>
                  <Text>{i18n.t('eyc_2024_1_header_text')}</Text>
                  {
                    keys &&
                    <KeysWrapper>
                      <img src={KeyBlue} alt='key' />
                      {
                        keys === 1 ?
                          <KeysText>{keys}&nbsp;{i18n.t('eyc_2024_1_header_oneToken')}</KeysText> :
                          <KeysText>{keys}&nbsp;{i18n.t('eyc_2024_1_header_tokens')}</KeysText>
                      }
                    </KeysWrapper>
                  }
                </> : (
                keys ?
                  <>
                    <Title>{i18n.t('eyc_2024_2_header_title')}</Title>
                    <Text>{i18n.t('eyc_2024_2_header_text')}</Text>
                    <KeysWrapper>
                      <img src={KeyGold} alt='key' />
                      {
                        keys === 1 ?
                          <KeysText>{keys}&nbsp;{i18n.t('eyc_2024_2_header_oneToken')}</KeysText>:
                          <KeysText>{keys}&nbsp;{i18n.t('eyc_2024_2_header_tokens')}</KeysText>
                      }
                    </KeysWrapper>
                  </> :
                  <>
                    <Title>{i18n.t('eyc_2024_2_header_noToken_title')}</Title>
                    <Text>{i18n.t('eyc_2024_2_header_noToken_text')}</Text>
                  </>
              )
            }

            <ButtonWrapper>
              <Button backgroundcolor={'#F37427'}
                      text={!keys && phase === 2 ? i18n.t('eyc_2024_rewards_btn') : i18n.t('eyc_2024_play_btn')}
                      url={!keys && phase === 2 ? '/rewards/all' : '/chest-opening-game'}
              />
            </ButtonWrapper>
          </div>
        </Content>
      </Container>
    </Wrapper>
  )
};

export default HeaderLoggedIn;