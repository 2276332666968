import React, { useMemo } from 'react';
import EYCHeader from './components/EYCHeader';
import EYCInfo from './components/EYCInfo';

// import React, { useEffect, useMemo, useRef } from 'react';
// import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { EYCHeader, EYCGame } from './components';
// import ModalQuestions from './components/EYCGame/parts/ModalQuestions';
// import { shopLang } from '../../../../config/config';
// import { useRecaptcha, useLoader, useImages } from '../../../../redesign/hooks';
import { useLoader, useImages } from '../../../../redesign/hooks';
// import APIService from '../apiEndYearCampaign/api';
// import { addGTM, addGTMLocalisationBE, addGTMLocalisationNL } from '../../../../components/utils/_gtm-utils';
// import { isIOS } from '../apiEndYearCampaign/utils';
import { checkLoggedIn } from '../../../../components/utils/_app-helpers';
// import { reCAPTCHA } from './api/const';
// import { Wrapper } from './EndYearCampaignCustom.styles';
// import * as gifs from './assets/gifs';
import * as images from './assets';

const EYCCampaign = () => {
    // const location = useLocation();
    const assets = useMemo(() => images,[]);
    // const assets = useMemo(() => isIOS ? {...gifs, ...images} : images,[]);
    const isAssetsLoaded = useImages(assets);
    // const refGame = useRef<HTMLDivElement>(null);
    // const { recaptcha } = useRecaptcha(`${reCAPTCHA}`);
    // const locale = shopLang.slice(0, 2).toLowerCase();
    const isLoggedIn = checkLoggedIn();
    const isCurrentUserDataLoaded = useSelector((state: any) => state?.isCurrentUserDataLoaded);
    // const isStarted = useSelector((state: any) => state?.infoEYC?.campaignGames[0]?.isStarted);
    // const isFinished = useSelector((state: any) => state?.infoEYC?.campaignGames[0]?.isFinished);
    // const accountId = localStorage.getItem('accountId');
    // const currentUser = useSelector((state: any) => state?.currentUser);
    // const cardNumber = useSelector((state: any) => state?.currentUser?.masterCardNumber);
    // const campaignId = useSelector((state: any) => state?.infoEYC?.id);
    // const loader = useLoader( !isAssetsLoaded || !isStarted || !recaptcha);
    const loader = useLoader( !isAssetsLoaded || (isLoggedIn ? !isCurrentUserDataLoaded : false));
    //
    // //Analytics for End Year Campaign 2023
    // useEffect(() => {
    //     if(shopLang === 'nl_nl'){
    //         addGTM('/count0/extwinq4+standard', { u1: location.pathname }, 'DC-3743070');
    //         addGTMLocalisationNL('extwinq4', 'count0');
    //     }else if (shopLang === 'be_nl'){
    //         addGTM('/essoe0/2023_001+unique', { u1: shopLang }, 'DC-4212618');
    //         addGTMLocalisationBE('2023_001');
    //     }else{
    //         addGTM('/essoe0/2023_000+unique', { u1: shopLang }, 'DC-4212618');
    //         addGTMLocalisationBE('2023_000');
    //     }
    // }, [location.pathname]);
    // //End Analytics for End Year Campaign 2023
    //
    // useEffect(() => {
    //     APIService.getCampaignBySlug(`ey-2023-${locale}`)
    //       .catch(() => {
    //           console.error('Something is wrong in getCampaignBySlug');
    //       })
    // },[locale])
    //
    // const dataSignIn = useMemo(() => {
    //     return {
    //         email: `${accountId}@example.com`,
    //         firstName: '',
    //         lastName: '',
    //         campaignId: campaignId,
    //         data: {
    //             accountId: accountId || '',
    //             cardNumber: cardNumber || ''
    //         }
    //     }
    // }, [campaignId, accountId, cardNumber])
    //
    // useEffect(() => {
    //     if(isLoggedIn && currentUser && !!currentUser.accountID && campaignId && isStarted && !isFinished && recaptcha){
    //         APIService.signIn({...dataSignIn})
    //             .then(() => {
    //                 console.info('user signs in');
    //                 return APIService.lotterySync()
    //             })
    //             .then(()=> APIService.updateUser())
    //             .then(() => APIService.setTransactions())
    //             .catch((err) => {
    //                 console.error(err);
    //                 console.info(`user doesn't sign in`)
    //             })
    //     }
    // },[isLoggedIn, isStarted, currentUser, currentUser.accountID, campaignId, dataSignIn, isFinished, recaptcha])
    //
    // useEffect(() => {
    //     return () => {
    //         APIService.setIsUserWon(false);
    //         APIService.setIsReplayGame(false);
    //         APIService.setActiveElement(0);
    //         APIService.setIsElementAnimated(false);
    //         APIService.setIsGameResultAnimated(false);
    //         APIService.setIsShowModal(false);
    //         APIService.setIsModalAnswerCorrect(false);
    //         APIService.setIsDefineWinnerCompleted(false);
    //
    //         console.log('clearStatesToInitial');
    //     }
    // },[])

    return (
      loader ||
      <>
        <EYCHeader/>
        <EYCInfo/>
      </>
        // loader ||
        // <Wrapper>
        //     <EYCHeader refGame={refGame}/>
        //     <EYCGame refGame={refGame}/>
        //     {shopLang !== 'nl_nl' && <ModalQuestions />}
        // </Wrapper>
    )
};

export default EYCCampaign;