/*
 * action types
 */
export const GET_INFO_EYC = 'GET_INFO_EYC';
export const ADD_AUTH_DATA_EYC = 'ADD_AUTH_DATA_EYC';
export const ADD_USER_DATA_EYC = 'ADD_USER_DATA_EYC';
export const SET_TRANSACTIONS_EYC = 'SET_TRANSACTIONS_EYC';
export const SET_PARTICIPANT_EYC = 'SET_PARTICIPANT_EYC';
export const SET_CURRENT_PRIZE_EYC = 'SET_CURRENT_PRIZE_EYC';
export const SET_IS_USER_WON_EYC = 'SET_IS_USER_WON_EYC';
export const SET_IS_SHOW_MODAL_EYC = 'SET_IS_SHOW_MODAL_EYC';
export const SET_IS_MODAL_ANSWER_CORRECT_EYC = 'SET_IS_MODAL_ANSWER_CORRECT_EYC';
export const SET_IS_REPLAY_GAME_EYC = 'SET_IS_REPLAY_GAME_EYC';
export const SET_ACTIVE_ELEMENT_EYC = 'SET_ACTIVE_ELEMENT_EYC';
export const SET_IS_ELEMENT_ANIMATED_EYC = 'SET_IS_ELEMENT_ANIMATED_EYC';
export const SET_IS_GAME_RESULT_ANIMATED_EYC = 'SET_IS_GAME_RESULT_ANIMATED_EYC';
export const SET_IS_DEFINE_WINNER_COMPLETED_EYC = 'SET_IS_DEFINE_WINNER_COMPLETED_EYC';
/*
 * action creators
 */

export function getCampaignInfo(data) {
  return {
    type: GET_INFO_EYC,
    data
  }
}

export function addAuthData (data) {
  const { refreshToken, refreshExpiresAt } = data;

  refreshToken
    ? window.localStorage.setItem('refreshToken', refreshToken)
    : window.localStorage.removeItem('refreshToken');

  refreshExpiresAt
    ? window.localStorage.setItem('refreshExpiresAt', `${refreshExpiresAt}`)
    : window.localStorage.removeItem('refreshExpiresAt');

  return {
    type: ADD_AUTH_DATA_EYC,
    data
  }
}

export function addUserData(data){
  return {
    type: ADD_USER_DATA_EYC,
    data
  }
}

export function setTransactions(data){
  return {
    type: SET_TRANSACTIONS_EYC,
    data
  }
}

export function setParticipant(data){
  return {
    type: SET_PARTICIPANT_EYC,
    data
  }
}

export function setCurrentPrize(data){
  return {
    type: SET_CURRENT_PRIZE_EYC,
    data
  }
}

export const setIsUserWon = (data) => ({
  type: SET_IS_USER_WON_EYC,
  data
});

export const setIsShowModal = (data) => ({
  type: SET_IS_SHOW_MODAL_EYC,
  data
})

export const setIsModalAnswerCorrect = (data) => ({
  type: SET_IS_MODAL_ANSWER_CORRECT_EYC,
  data
})

export const setIsReplayGame = (data) => ({
  type: SET_IS_REPLAY_GAME_EYC,
  data
})

export const setActiveElement = (data) => ({
  type: SET_ACTIVE_ELEMENT_EYC,
  data
})

export const setIsElementAnimated = (data) => ({
  type: SET_IS_ELEMENT_ANIMATED_EYC,
  data
})

export const setIsGameResultAnimated = (data) => ({
  type: SET_IS_GAME_RESULT_ANIMATED_EYC,
  data
})

export const setIsDefineWinnerCompleted = (data) => ({
  type: SET_IS_DEFINE_WINNER_COMPLETED_EYC,
  data
})