import React from 'react';
import { useLocation } from 'react-router-dom';
import { checkLoggedIn } from '../../../../../../components/utils/_app-helpers';
import useWindowSize from '../../../../../../redesign/hooks/useWindowSize';
import { Button } from '../../../../../../redesign/components/shared';
import i18n from '../../../../../../config/i18n';
import {
  Wrapper,
  Container,
  ImageWrapper,
  ContentWrapper,
  PreTitle,
  Title,
  Text,
  Remark,
  ButtonWrapper
} from './EYCInfo.styles';
import {
  Info1,
  Info2
} from '../../assets';

const EYCInfo = () => {
  const isLoggedIn = checkLoggedIn();
  const location = useLocation();
  const windowSize = useWindowSize();
  const phase: number = 2;

  const Image =
    <ImageWrapper>
      <img src={phase === 1 ? Info1 : Info2} alt='info' />
    </ImageWrapper>;

  return (
    <Wrapper>
      <Container>
        {phase === 1 ?
          <>
            {windowSize >= 768 && Image}
            <ContentWrapper>
              <PreTitle>{i18n.t('eyc_2024_1_info_preTitle')}</PreTitle>
              <Title>{i18n.t('eyc_2024_1_info_title')}</Title>
              <Text>{i18n.t('eyc_2024_1_info_text')}</Text>
              {windowSize < 768 && Image}
              <Remark>{i18n.t('eyc_2024_1_info_remark')}</Remark>

              <ButtonWrapper>
                <Button buttontype={'primary'}
                        text={isLoggedIn ? i18n.t('eyc_2024_play_btn2') : i18n.t('eyc_2024_loggedOut_btn')}
                        url={isLoggedIn ? '/chest-opening-game' : { pathname: '/auth/login', state: { prevPath: location.pathname }}}
                />
              </ButtonWrapper>
            </ContentWrapper>
          </> :
          <>
            {windowSize >= 768 && Image}
            <ContentWrapper>
              <Title>{i18n.t('eyc_2024_2_info_title')}</Title>
              <Text>{i18n.t('eyc_2024_2_info_text')}</Text>
              {windowSize < 768 && Image}

              <ButtonWrapper>
                <Button buttontype={'primary'}
                        text={i18n.t('eyc_2024_rewards_btn')}
                        url={'/rewards/all'}
                />
              </ButtonWrapper>
            </ContentWrapper>
          </>
        }
      </Container>
    </Wrapper>
  );
};

export default EYCInfo;